<template>
	<div>
		<b-row>
			<b-col cols="12">
				<div class="position-relative">
					<div class="sign">F1</div>
					<div id="model" style="width: 100vw; height: 100vh"></div>
					<b-card class="position-absolute" style="right: 0; top: 0; background: rgba(255, 255, 255, 0.3); z-index: 99999">
						<h4>门禁-东1</h4>
						<div class="border-bottom pb-1">
							<p class="d-flex justify-content-between">
								开关状态
								<span style="width: 1.5rem; height: 1.5rem; border-radius: 50%; background: #d7d7d7"></span>
							</p>
							<p class="d-flex justify-content-between">
								报警状态
								<span class="d-flex align-items-center"> <b-icon icon="exclamation-triangle-fill" style="width: 1.5rem; height: 1.5rem; margin-right: 0.5rem"> </b-icon>正常 </span>
							</p>
							<div class="d-flex justify-content-center">
								<b-button size="sm">远程开门</b-button>
							</div>
						</div>
						<div class="pt-1">
							<h5>人员出入记录</h5>
							<b-table :items="items" small style="height: calc(100vh - 25rem - 25rem)"></b-table>
						</div>
					</b-card>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import { onMounted, reactive, ref, toRefs } from "@vue/composition-api";
	import { setupViewer } from "@/libs/forge/app";
	import { getModelPath } from "@/libs/forge/utils/tools";
	import * as MyAwesomeExtension from "@/libs/forge/extensions/backgroud";
	import * as SelectModel from "@/libs/forge/extensions/selectModel";
	import * as TurnTable from "@/libs/forge/extensions/camareRotate";

	export default {
		name: "index",
		setup() {
			const data = reactive({
				items: [
					{ column1: "", column2: "", column3: "" },
					{ column1: "", column2: "", column3: "" },
					{ column1: "", column2: "", column3: "" },
					{ column1: "", column2: "", column3: "" },
					{ column1: "", column2: "", column3: "" },
					{ column1: "", column2: "", column3: "" },
					{ column1: "", column2: "", column3: "" },
					{ column1: "", column2: "", column3: "" },
				],
			});
			const viewer = ref(null);
			onMounted(() => {
				const modelBox = document.getElementById("model");

				setupViewer(modelBox, viewer, getModelPath(72, "AccessControl/AccessControl"), ["TurnTable", "MyAwesomeExtension", "SelectModel"]);
			});
			return {
				...toRefs(data),
			};
		},
	};
</script>

<style lang="scss" scoped>
	.sign {
		position: absolute;
		top: 0;
		left: 0;
		width: 6rem;
		height: 3rem;
		background: #8bcdea;
		text-align: center;
		line-height: 3rem;
		color: #ffffff;
		font-size: 2rem;
		font-weight: bolder;
		border-radius: 0.2rem;
	}
</style>
